<template>
  <div>
    <div class="text-md-right mb-4">
      <router-link
          :to="{ name: 'agency.payment-methods.create' }"
          class="btn btn-dark-blue btn-sm mr-4"
      >
        <i class="uil uil-plus"></i> New Payment Method
      </router-link>
    </div>
    <template v-if="!loadingMethods">
      <div class="table-responsive mb-0">
        <b-table
            v-if="user"
            :items="paymentMethods"
            :fields="datatable.columns"
        >
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>

          <template v-slot:cell(last_four)="data">
            ********* {{ data.item.last_four }}
          </template>
          <template v-slot:cell(default)="data">
            <feather
                v-if="data.item.id === user.default_payment_method"
                type="check"
                class="icon-dual-success"
            />
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
                v-if="data.item.id !== user.default_payment_method"
                variant="link"
                class="btn-action text-primary"
                title="Set Default"
                :disabled="loadingSetDefault === data.item.id"
                @click="setDefault(data.item)"
            >
              <b-spinner
                  v-if="loadingSetDefault === data.item.id"
                  small
              />
              <i v-else class="uil uil-check"></i>
            </b-button>
            <b-button
                v-if="data.item.id !== user.default_payment_method"
                variant="link"
                title="Delete"
                class="text-danger btn-delete"
                :disabled="loadingRemove === data.item.id"
                @click="remove(data.item)"
            >
              <b-spinner v-if="loadingRemove === data.item.id" small />
              <i v-else class="uil uil-trash-alt"></i>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'brand' },
          { key: 'last_four', label: 'Card number' },
          { key: 'default' },
          { key: 'actions', class:'actions' },
        ],
      },
      loadingMethods: false,
      loadingRemove: null,
      loadingSetDefault: null,
    }
  },

  computed: {
    paymentMethods() {
      return this.$store.getters['paymentMethod/all'] || []
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    if (!this.$store.getters['paymentMethod/all']) {
      this.getPaymentMethods()
    }
  },

  methods: {
    getPaymentMethods() {
      this.loadingMethods = true

      this.$store
        .dispatch('paymentMethod/getAll')
        .then((res) => {
          this.loadingMethods = false
        })
        .catch(() => {
          this.loadingMethods = false
        })
    },

    remove(paymentMethod) {
      this.loadingRemove = paymentMethod.id

      this.$store
        .dispatch('paymentMethod/remove', paymentMethod.id)
        .then((res) => {
          this.loadingRemove = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    setDefault(paymentMethod) {
      this.loadingSetDefault = paymentMethod.id

      this.$store
        .dispatch('paymentMethod/setDefault', paymentMethod.id)
        .then((res) => {
          this.loadingSetDefault = null
        })
        .catch(() => {
          this.loadingSetDefault = null
        })
    },
  },
}
</script>
