<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
           <SettingsPaymentMethods/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsPaymentMethods from "@components/Agency/PaymentMethods/SettingsPaymentMethods";
export default {
 components:{
   SettingsPaymentMethods
 }
}
</script>
